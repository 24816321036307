#language-switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-color: inherit;
  border-color: inherit;
  border-radius: 0px;
  color: #27272e;
  box-shadow: none;
}

.dropdown-menu.show {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border: transparent;
  padding: 10px;
  border-radius: 4px;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  color: #27272e;
  border-radius: 4px;
}

.dropdown-item:hover {
  /*   color: var(--dropdown-hover-text-border-color);
  background-color: var(--dropdown-hover-background-color);
  border-color: var(--dropdown-hover-background-color); */
}

.dropdown-item.active {
  /*    color: var(--dropdown-active-background-color);
  background-color: var(--dropdown-active-text-border-color);
  border-color: var(--dropdown-active-background-color);  */
}
