.app-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-header {
  flex: 2.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-body {
  flex: 7.8;
}

.box {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 350px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-content {
  padding: 30px;
}

.box-icon {
  display: flex;
  justify-content: center;
}

.box-title {
  text-align: center;
  color: #27272e;
  margin-top: 20px;
}

.box-main-title {
  font-size: 28px;
  font-weight: bold;
}

.box-sub-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
}

.box-form {
  margin-top: 20px;
}

.box-form-label {
  font-size: 14px;
  width: 100%;
  color: #425466;
}

.box-form-input {
  color: #425466;
  font-size: 14px;
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  padding: 20px;
}

.box-form-input::placeholder {
  color: #b5b5bd;
}

.box-form-input:focus {
  border: 0px solid #4c6fff;
  box-shadow: 0 0 3px #4c6fff;
  outline-offset: 0px;
  outline: none;
}

.box-form-input-error {
  color: #ff0000;
  font-size: 11px;
}

.box-button-single {
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
}

.box-button-left {
  width: 100%;
  margin-top: 20px;
  margin-right: 15px;
  font-weight: bold;
}

.box-button-right {
  width: 100%;
  margin-top: 20px;
  margin-left: 15px;
  font-weight: bold;
}

.form-check-input {
  background-color: #ffffff;
  border-color: #a7a7a7;
}
.form-check-input:hover {
  background-color: #f1f1f1;
  border-color: #a7a7a7;
}
.form-check-input:checked {
  background-color: #66cb9f;
  border-color: #ffffff;
}
.form-check-input:checked:hover {
  background-color: #42b181;
  border-color: #42b181;
}
.form-check-input:focus {
  border-color: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .box {
    margin-left: 20px;
    margin-right: 20px;
  }
}
