#root,
.app {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-style: normal;
  height: 100%;
  background-color: #f7fafc;
}

body {
  margin: 0;
  height: 100vh;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
